import React from "react";
import { NavLink } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4 content-center desktop-button-only">
            <div className="row ">
              <div className="call-box">
                <div className="call-icon">
                  <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                </div>
                <div className="call-content">
                  <h5>Call Us Today</h5>
                  <p>
                    <a className="black-text" href="tel:905-671-4383">
                      905-671-4383
                    </a>
                    <br />
                    <a className="black-text" href="tel:647-462-0514">
                      647-462-0514
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 content-center">
            <div className="section">
              <img
                src="/Assets/icons/logo.png"
                alt="Your Logo"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-4 content-center desktop-button-only">
            <div className="call-box">
              <div className="call-icon">
                <img src="/Assets/flaticon/email.png" alt="..." />
              </div>
              <div className="call-content">
                <h5>Email Us Today</h5>
                <p className="email-link">
                  <a href="mailto:hr@jobs-ontario.com">hr@jobs-ontario.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg owm-nav">
        <div className="container">
          <NavLink to="/contact" className="btn btn-owm-primary my-2 my-sm-0 pull-right mobile-button-only" >
                    Apply Online
                </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i className="fas fa-bars"></i>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <NavLink to="/home" className="nav-link white-text" activeClassName="selected">
                    Home
                </NavLink>
              </li>
              <li className="nav-item">
              <NavLink to="/services" className="nav-link white-text" activeClassName="selected">
                  Our Services
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className="nav-link white-text" activeClassName="selected">
                    About Us
                </NavLink>
              </li>
              <li className="nav-item">
              <NavLink to="/contact" className="nav-link white-text" activeClassName="selected">
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <NavLink to="/contact" className="btn btn-owm-primary my-2 my-sm-0 pull-right desktop-button-only" >
                    Apply Online
                </NavLink>
            {/* <a
              href="/apply"
              className="btn btn-owm-primary my-2 my-sm-0 pull-right desktop-button-only"
              type="submit"
            >
              Apply Online
            </a> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Home;
