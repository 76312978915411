import React from 'react';

function Home() {
  return (
    <>
        <div className="hero">
        <div className="container">
            <div className="hero-content">
                <h1 className="tagline1">Open Doors to Opporutinity</h1>
                <h2 className="tagline2">Your Job Search Starts Here</h2>
                <a href="/apply" className="btn btn-owm-primary btn-btn-primary">Apply Now</a>
            </div>
        </div>
    </div>
    <div className="feature-job-posting ">
        <div className="container reveal">
            <div id="accordion">
                <div className="card">
                    <div className="" id="headingOne">
                        <button className="big-expandable-button" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            <h2>Feature Job Postings &nbsp;<i className="fas fa-chevron-down"></i></h2>
                        </button>
                    </div>

                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body fjp-content">
                            <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col">Company</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Category</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Mississauga, ON</td>
                                    <td>General Labour</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Mississauga, ON</td>
                                    <td>Warehouse</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Mississauga, ON</td>
                                    <td>Forklift Drivers</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Mississauga, ON</td>
                                    <td>G Drivers</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>London, ON</td>
                                    <td>General Labour</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>London, ON</td>
                                    <td>Warehouse</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>London, ON</td>
                                    <td>Forklift Drivers</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>London, ON</td>
                                    <td>G Drivers</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Kingston, ON</td>
                                    <td>General Labour</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Kingston, ON</td>
                                    <td>Warehouse</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Kingston, ON</td>
                                    <td>Forklift Drivers</td>
                                  </tr>
                                  <tr>
                                    <td>JOBS @ Ontario Work Force</td>
                                    <td>Kingston, ON</td>
                                    <td>G Drivers</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div id="jobs-by-industry" className="jobs-by-industry bg-last-layer reveal">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className="jobs-by-industry-h1">Browse Jobs By Industries</h1>
                </div>
            </div>
            <div className="bg-box row">
                <div className="blue-box">
                </div>
                <div className="gray-blue-box">
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/transport.png" alt="..." />
                        </div>
                        <div className="content">Logistics</div>
                        <div className="arrow  hidden-element hide">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/check-in.png" alt="..." />
                        </div>
                        <div className="content">Hospitality</div>
                        <div className="arrow  hidden-element hide">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/manufacturing.png" alt="..." />
                        </div>
                        <div className="content">Manufacturing</div>
                        <div className="arrow  hidden-element">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/academic.png" alt="..." />
                        </div>
                        <div className="content">Education</div>
                        <div className="arrow  hidden-element">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/working-on-a-pc.png" alt="..." />
                        </div>
                        <div className="content">Software/IT</div>
                        <div className="arrow  hidden-element">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="rec-box">
                        <div className="icon">
                            <img src="/Assets/flaticon/stethoscope.png" alt="..." />
                        </div>
                        <div className="content">Front Line Support</div>
                        <div className="arrow  hidden-element hide">
                            <i className="fas fa-chevron-right"></i>
                        </div>
                    </div>
                </div>





            </div>

        </div>

    </div>

    <div className="location">
        <div className="container reveal">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className="location-h1">Our Locations</h1>
                </div>
            </div>
            <div className="card-deck">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            Kingston Office<br/><span>&nbsp;</span>
                        </h5>
                    </div>
                    <iframe className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2858.486821195908!2d-76.57952612317982!3d44.238222814396586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cd2acfc3f3b83bf%3A0x91edd78b3d4f4901!2s1786%20Bath%20Rd%2C%20Kingston%2C%20ON%20K7M%204Y2!5e0!3m2!1sen!2sca!4v1683583902529!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 1786 Bath Rd, 
                            <br />Kingston, ON K7M 4Y2</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:613-384-2500">613-384-2500</a> <br />
                                <a className="black-text" href="tel:613-384-1634">613-384-1634</a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            (Head Office)<br/> Mississauga
                        </h5>
                    </div>
                    <iframe  className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.033934421401!2d-79.6648048231993!3d43.70984384876448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c1b2fa72991%3A0x93d45966b5ebec49!2s2722%20Rena%20Rd%2C%20Mississauga%2C%20ON%20L4T%203J9!5e0!3m2!1sen!2sca!4v1683583832984!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 2722 Rena Rd, 
                            <br />Mississauga, ON L4T 3J9</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:905-671-4348">905-671-4348</a> <br />
                                <a className="black-text" href="tel:647-462-0541">647-462-0541</a> 
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            London Office<br/><span>&nbsp;</span>
                        </h5>
                    </div>
                    <iframe  className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.0640432519435!2d-81.20085602322521!3d42.99798599456036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882eed7b1fc79cf7%3A0x85c39b9d0c8c4338!2s690%20Hale%20St%2C%20London%2C%20ON%20N5W%201H4!5e0!3m2!1sen!2sca!4v1683583951232!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 690 Hale St, <br />London, ON N5W 1H4</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:548-866-0164">548-866-0164</a> <br />
                                <a className="black-text" href="tel:647-472-0541">647-472-0541</a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    </>
  );
}

export default Home;
