import React from 'react';

function Contact() {
  return (
    <div className="location">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className="location-h1">Our Locations</h1>
                </div>
            </div>
            <div className="card-deck">
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            Kingston Office<br/><span>&nbsp;</span>
                        </h5>
                    </div>
                    <iframe className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2858.486821195908!2d-76.57952612317982!3d44.238222814396586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cd2acfc3f3b83bf%3A0x91edd78b3d4f4901!2s1786%20Bath%20Rd%2C%20Kingston%2C%20ON%20K7M%204Y2!5e0!3m2!1sen!2sca!4v1683583902529!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 1786 Bath Rd, 
                            <br />Kingston, ON K7M 4Y2</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:613-384-2500">613-384-2500</a> <br />
                                <a className="black-text" href="tel:613-384-1634">613-384-1634</a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            (Head Office)<br/> Mississauga
                        </h5>
                    </div>
                    <iframe  className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.033934421401!2d-79.6648048231993!3d43.70984384876448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c1b2fa72991%3A0x93d45966b5ebec49!2s2722%20Rena%20Rd%2C%20Mississauga%2C%20ON%20L4T%203J9!5e0!3m2!1sen!2sca!4v1683583832984!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 2722 Rena Rd, 
                            <br />Mississauga, ON L4T 3J9</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:905-671-4348">905-671-4348</a> <br />
                                <a className="black-text" href="tel:647-462-0541">647-462-0541</a> 
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">
                            London Office<br/><span>&nbsp;</span>
                        </h5>
                    </div>
                    <iframe  className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.0640432519435!2d-81.20085602322521!3d42.99798599456036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882eed7b1fc79cf7%3A0x85c39b9d0c8c4338!2s690%20Hale%20St%2C%20London%2C%20ON%20N5W%201H4!5e0!3m2!1sen!2sca!4v1683583951232!5m2!1sen!2sca"
                        width="370" height="200" style={{border: 0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                        <p className="card-text"><strong>Address:</strong> <br/> 690 Hale St, <br />London, ON N5W 1H4</p>
                        <div className="call-box">
                            <div className="call-icon">
                                <img src="/Assets/flaticon/telephone-call.png" alt="..." />
                            </div>
                            <div className="call-content">
                                <h5>Call Us Today</h5>
                                <a className="black-text" href="tel:548-866-0164">548-866-0164</a> <br />
                                <a className="black-text" href="tel:647-472-0541">647-472-0541</a> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
  );
}

export default Contact;
