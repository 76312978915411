import React from 'react';

function About() {
  return (
    <section className="container mt-4">
      <h2>Welcome to Jobs @ Ontario work force!</h2>
      <p>
        At Jobs @ Ontario work force, we are dedicated to empowering individuals and businesses by connecting talent with opportunity. As a leading provider of workforce solutions, we understand the dynamic nature of today's job market and strive to bridge the gap between job seekers and employers.
      </p>
      
      <p>
        Our mission is to create a thriving and inclusive workforce ecosystem where every individual has the chance to achieve their career goals and every business can find the perfect match for their staffing needs. Whether you are a job seeker looking for new opportunities or an employer seeking top talent, we are here to support you every step of the way.
      </p>

      <p>
      What sets us apart is our commitment to personalized service. We believe that every person is unique and deserves customized assistance in their job search or talent acquisition journey. Our team of experienced professionals takes the time to understand your specific needs, aspirations, and challenges, ensuring that we provide tailored solutions that yield exceptional results.
      </p>
      
      <p>
      For job seekers, we offer a comprehensive range of services to help you navigate the job market successfully. From career counseling and resume building to interview preparation and skill development, we equip you with the tools and knowledge to stand out in a competitive landscape. We also maintain a vast network of employers across various industries, enabling us to connect you with exciting opportunities that align with your skills and interests.
      </p>

      <p>
      If you are an employer, we understand that finding the right talent is crucial for your organization's success. Our recruitment experts are adept at identifying qualified candidates who not only possess the necessary skills but also fit seamlessly into your company culture. We streamline the hiring process, saving you time and resources while ensuring you have access to a diverse pool of exceptional talent.
      </p>
      <p>
      At Jobs @ Ontario work force, we are driven by our core values of integrity, collaboration, and excellence. We are committed to fostering an environment of trust, respect, and professionalism in all our interactions. Our aim is not just to fulfill immediate needs but to forge long-lasting partnerships that contribute to the growth and success of individuals and businesses alike.
      </p>
      <p>
      Thank you for choosing Jobs @ Ontario work force as your trusted partner. We look forward to serving you and making a positive impact on your professional journey or talent acquisition strategy. Together, let's unlock the power of possibilities and build a brighter future.
      </p>
      <p>
      Contact us today to discover how we can help you thrive!
      </p>
    </section>
  );
}

export default About;
