import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home'
import Contact from './Pages/Contact';
import About from './Pages/About';
import OurService from './Pages/OurService';
import Header from './SharedComponents/Header'
import Footer from './SharedComponents/Footer'
function App() {

  return (
    <div className="App">
      <Router>
      <Header />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<OurService />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
